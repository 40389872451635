<template>
  <div class="list-info">
    <top-bar :title="'社区码'" :left="true"></top-bar>
    <div class="search">
      <input v-model="dataForm.name" type="search" value="搜索" placeholder="输入姓名进行搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="dateBar">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{ dataForm.startDates == "" ? "最早" : dataForm.startDates }}</span></van-col>
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{ dataForm.endDates == "" ? "至今" : dataForm.endDates }}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup
      v-model="beginDateShow"
      position="bottom">
      <van-datetime-picker
        @confirm="beginDateConfim"
        @cancel="beginDateShow = false"
        type="date"
        title="选择年月日"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date()"
      />
    </van-popup>
    <van-popup
      v-model="endDateShow"
      position="bottom">
      <van-datetime-picker
        @confirm="endDateConfim"
        @cancel="endDateShow = false"
        type="date"
        title="选择年月日"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date(2025, 10, 1)"
      />
    </van-popup>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="list-item" v-for="item in dataList" :key="item.id">
            <div class="content">
              <div class="content-text">
                <div class="top-text">{{item.userName}}</div>
                <div class="right-text" :style="{color:item.userId>0?'#007aff':'#ff5e5e'}">{{item.userId>0?'已登记':'未登记'}}</div>
                <div class="bottom-text">{{item.registerTime?'登记时间'+item.registerTime:'扫码时间'+item.createTime}}</div>
              </div>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate, formatterDateTimeWithOutSec } from "@/utils/utils";
export default {
  components: {
    topBar,
  },
  mounted() {
    this.getDataList()
  },
  data() {
    return {
      pullLoading: false,
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dataForm: {
        page: 0,
        limit: 5,
        startDates: '',
        endDates: '',
        name: ''
      },
      dataList: []
    }
  },
  methods: {
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      })
      this.dataForm.page++
      this.$http({
        url: this.$http.adornUrl("/wxapp/registerLog/list"),
        method: "post",
        params: this.$http.adornParams(this.dataForm)
      }).then(
        ({ data }) => {
          //debugger
          if (data.code == 0) {
            this.pullLoading =false
            if (this.dataList.length == data.page.totalCount) {
              this.$toast.clear();
              this.finished = true;
              return;
            }
            if(this.dataForm.page==1&&data.page.list.length==0){
              this.dataList=[]
            }
            this.dataList = this.dataList.concat(data.page.list);
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.dataForm.page = 0;
      this.getDataList()
    },
    onSearch (val) {
      this.dataForm.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.dataForm.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim(value) {
      let d = new Date(this.dataForm.startDates);
      if (
        this.dataForm.startDates != "" &&
        d.getTime(d) < value.getTime(value)
      ) {
        return this.$toast.fail("开始时间不能比结束时间大哦");
      }
      this.dataForm.startDates = formatterDate(value);
      this.beginDateShow = false;
    },
    endDateConfim(value) {
      let d = new Date(this.dataForm.endDates);
      if (this.dataForm.endDates != "" && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail("结束时间不能比开始时间小哦");
      }
      this.dataForm.endDates = formatterDate(value);
      this.endDateShow = false;
    },
    changeDate() {
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    dateClose() {
      this.dataForm.endDates = "";
      this.dataForm.startDates = "";
    },
  },
};
</script>

<style scoped>
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
</style>
